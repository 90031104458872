(function() {

    const phone = document.getElementById('phone');
    const phoneVideo = document.getElementById('phone-video');
    const phoneImages = document.getElementsByClassName('phone-image');

    if (phone && phoneVideo) {
        phone.addEventListener('click', playVideo);

        let i = 0;
        let nextImage;
        const reset = phoneImages.length - 1;

        setInterval(() => {
            const inc = i;
            const nextImage = (i === reset) ? phoneImages[0] : phoneImages[i].nextElementSibling;

            nextImage.classList.add('phone-active');

            setTimeout(() => {
                phoneImages[inc].classList.remove('phone-active');
            }, 500)

            i = (i === reset) ? 0 : i + 1;

        }, 3000);
    }

    function playVideo() {
        phone.classList.add('phone-play');
        phoneVideo.src += '&autoplay=1';
        phone.removeEventListener('click', playVideo);
    }

})();
